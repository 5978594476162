<template>
  <!-- Table Container Card -->
  <b-overlay
    :show="showOverlay"
    rounded="sm"
  >
    <b-card title="Preventivatore">


      <!-- Inserimento dati -->

      <!-- {{ Polizza }}-->
      <form-wizard
        ref="formValidate"
        :key="controllo_conferma_documento"
        color="#7367F0"
        :title="null"
        :subtitle="null"
        shape="square"
        finish-button-text="Submit"
        back-button-text="Previous"
        class="mb-3"
        @on-complete="salvaDocumento"
      >
        <!-- Specchietto  -->
        <b-row v-if="Quotation.Id">
          <b-col
            v-if="Quotation.Id"
            md="6"
          >
            <b-card v-if="Quotation.Id">
              <b-row>
                <b-col
                  md="4"
                  offset="3"
                >
                  <h2>Numero preventivo</h2>
                </b-col>
                <b-col md="4">
                  <h2>
                    {{ Quotation.numero_preventivo }}
                    <span v-if="Quotation.isConfirmed != 'Y'"> ( Preventivo )</span>
                  </h2>
                  <span
                    v-if="Quotation.isConfirmed == 'R'"
                    class="small"
                  >
                    Confermato per l'attivazione
                  </span>
                </b-col>
                <b-col
                  md="5"
                  offset="3"
                >
                  Imponibile
                </b-col>
                <b-col md="4">
                  € {{ Quotation.imponibile }}
                </b-col>
                <b-col
                  md="5"
                  offset="3"
                >
                  Imposte
                </b-col>

                <b-col md="4">
                  € {{ Quotation.imposte }}
                </b-col>
                <b-col md="12">
&nbsp;
                </b-col>
                <b-col
                  md="5"
                  offset="3"
                >
                  <strong><big>Totale</big></strong>
                </b-col>
                <b-col md="4">
                  <strong><big> € {{ Quotation.totale }} </big>
                  </strong>
                </b-col>
                <b-col md="12">
&nbsp;
                </b-col>
                <b-col
                  v-if="agency.mostrareProvvigioni=='Y' || $can('create', 'Dealer') || $can('write', 'Dealer')"
                  md="5"
                  offset="3"
                >
                  Provvigione
                </b-col>
                <b-col
                  v-if="agency.mostrareProvvigioni=='Y' || $can('create', 'Dealer') || $can('write', 'Dealer')"
                  md="4"
                >
                  € {{ provvigione }}
                  <b-button
                    v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                    class="btn btn-icon"
                    variant="link"
                    @click="showProvvigione"
                  >
                    <feather-icon
                      icon="EyeOffIcon"
                      size="16"
                    />
                  </b-button>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <h5>Dettaglio Premi</h5>
                  <ul>
                    <li
                      v-for="p in Quotation.commissionDetails"
                      :key="p.Id"
                    >
                      <strong>{{ p.package.Name }}</strong>
                      <ul>
                        <li><strong>Imponibile: </strong> {{ p.imponibile }} </li>
                        <li><strong>Tasse: </strong>{{ p.tax }}  </li>
                        <li><strong>Totale: </strong> {{ p.totale }} </li>
                        <li v-if="$can('create', 'Dealer')">
                          <strong>Provvigione: </strong>{{ p.commission }}
                        </li>
                      </ul>
                    </li>
                  </ul>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col
            v-if="Quotation.Id"
            md="6"
          >
            <b-card v-if="Quotation.Id">
              <b-row>
                <b-col>
                  <h2>Prodotti selezionati</h2>

                  <div>
                    <strong>CVT Selezionata:</strong>
                    <ul v-if="Quotation.package">
                      <li>{{ Quotation.package.Name }}</li>
                    </ul>
                  </div>
                  <br>
                  <div>
                    <strong>Prodotti Complementari</strong>
                    <ul>
                      <li
                        v-for="(pk) in Quotation.secondaryPackages"
                        :key="pk.Id"
                      >
                        {{ pk.Name }}
                      </li>
                    </ul>
                  </div>

                </b-col>

              </b-row>

            </b-card>
          </b-col>
          <b-col>
            <b-row>
              <b-col md="12">
                <hr>
              </b-col>
              <b-col
                md="12"
                style="text-align: center"
              >
                <b-card>
                  <b-button
                    v-if="Quotation.isConfirmed == 'N'"
                    class="btn btn-space"
                    variant="warning"
                    @click="prontoPerAttivazione"
                  >
                    Conferma per l'attivazione
                  </b-button>


                  <b-button
                    v-if="Quotation.isConfirmed == 'R'"
                    class="btn btn-space"
                    variant="success"
                    @click="confermaAttivazionePreventivo"
                  >
                    Attiva Polizza
                  </b-button>

                  <b-button
                    class="btn btn-space"
                    @click="DownloadRenderFile(Quotation.Id, 'BPREV')"
                  >
                    Stampa preventivo
                  </b-button>

                  <b-button
                    v-b-modal.static-file-view-modal
                    class="btn btn-space"
                  >
                    Scarica documenti
                  </b-button>
                  <!--
          <b-button
            class="btn btn-space"
            variant="info"
          >
            Carica documenti
          </b-button>
          -->
                </b-card>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <!-- Specchietto Fine-->
        <!-- cliente  -->
        <tab-content
          title="Cliente"
          :before-change="validationFormCliente"
        >
          <validation-observer
            ref="clienteRules"
            :key="controllo_conferma_documento"
            tag="form"
          >
            <b-row>
              <b-col
                cols="12"
                class="mb-2"
              >
                <h5 class="mb-0">
                  Dati Cliente
                </h5>
                <small
                  class="text-muted"
                >Inserire i dati personali del cliente</small>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Cognome/Rag.Sociale"
                  label-for="last-name"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Cognome/Rag.Sociale"
                    rules="required"
                  >
                    <b-form-input
                      id="last-name"
                      v-model="Quotation.cognome_cliente"
                      placeholder="Rossi"
                      :state="errors.length > 0 ? false : null"
                      :disabled="Quotation.isConfirmed == 'Y' || Quotation.isConfirmed == 'R'"
                    />
                    <small
                      class="text-danger"
                    >*Campo obbligatorio</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Nome"
                  label-for="first-name"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Nome"
                  >
                    <b-form-input
                      id="first-name"
                      v-model="Quotation.nome_cliente"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Mario"
                      :disabled="Quotation.isConfirmed == 'Y'"
                    />
                    <small
                      v-if="errors[0]"
                      class="text-danger"
                    >*Campo obbligatorio</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  label="Codice Fiscale"
                  label-for="codice-fiscale"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Codice Fiscale"
                    :rules="`${controllo_conferma_documento?'required|CF':''}`"
                  >
                    <b-form-input
                      id="codice-fiscale"
                      v-model="Quotation.codice_fiscale"
                      :state="errors.length > 0 ? false : null"
                      :disabled="Quotation.isConfirmed == 'Y'"
                    />
                    <small
                      v-if="errors[0]"
                      class="text-danger"
                    >*Campo obbligatorio</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  label="Partita IVA"
                  label-for="partita-iva"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Partita Iva"
                    :rules="`${controllo_conferma_documento?'min:11':''}`"
                  >
                    <b-form-input
                      id="partita-iva"
                      v-model="Quotation.partita_iva"
                      :state="errors.length > 0 ? false : null"
                      placeholder=""
                      :disabled="Quotation.isConfirmed == 'Y'"
                    />
                    <small
                      v-if="errors[0]"
                      class="text-danger"
                    >Inserisci una P. IVA valida</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!--
              <b-col md="4">
                <b-form-group
                  label="Consenso Privacy"
                  label-for="consenso-policy"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Consenso Policy"
                  >
                    <b-form-checkbox
                      id="consenso-policy"
                      v-model="Quotation.consenso_policy"
                      :disabled="Quotation.isConfirmed == 'Y'"
                      name="check-button"
                      value="Y"
                      unchecked-value="N"
                      switch
                      inline
                    >
                      <span v-if="Quotation.consenso_plocy == 'Y'">Si</span>
                      <span v-else>No</span>
                    </b-form-checkbox>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              -->

              <b-col md="4">
                <b-form-group
                  label="Email"
                  label-for="email"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Email"
                    :rules="`${controllo_conferma_documento?'required|email':'email'}`"
                  >
                    <b-form-input
                      id="email"
                      v-model="Quotation.email"
                      :disabled="Quotation.isConfirmed == 'Y'"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small
                      v-if="errors[0]"
                      class="text-danger"
                    >Inserisci una email valida</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  label="Telefono"
                  label-for="telefono"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Telefono"
                  >
                    <b-form-input
                      id="telefono"
                      v-model="Quotation.telefono"
                      :disabled="Quotation.isConfirmed == 'Y'"
                      :state="errors.length > 0 ? false : null"
                      type="number"
                      placeholder=""
                    />
                    <small
                      v-if="errors[0]"
                      class="text-danger"
                    >*Campo obbligatorio</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  label="Cellulare"
                  label-for="cellulare"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Cellulare"
                    :rules="`${controllo_conferma_documento?'required':''}`"
                  >
                    <b-form-input
                      id="cellulare"
                      v-model="Quotation.cellulare"
                      :disabled="Quotation.isConfirmed == 'Y'"
                      :state="errors.length > 0 ? false : null"
                      type="number"
                      placeholder=""
                    />
                    <small
                      v-if="errors[0]"
                      class="text-danger"
                    >*Campo obbligatorio</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="8">
                <b-form-group
                  label="Indirizzo"
                  label-for="indirizzo"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Indirizzo"
                    :rules="`${controllo_conferma_documento?'required':''}`"
                  >
                    <b-form-input
                      id="indirizzo"
                      v-model="Quotation.indirizzo"
                      :disabled="Quotation.isConfirmed == 'Y'"
                      :state="errors.length > 0 ? false : null"
                      placeholder=""
                    />
                    <small
                      v-if="errors[0]"
                      class="text-danger"
                    >*Campo obbligatorio</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <validation-provider
                  #default="{ errors }"
                  name="Nazione"
                  rules="required"
                >
                  <b-form-group
                    label="Nazione"
                    label-for="nazione"
                    :state="errors.length > 0 ? false : null"
                  >
                    <v-select
                      id="nazione"
                      v-model="Quotation.nazione"
                      :disabled="true"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="countries"
                      label="name"
                      input-id="Id"
                    />
                    <b-form-invalid-feedback
                      :state="errors.length > 0 ? false : null"
                    >
                      <small
                        class="text-danger"
                      >*Campo obbligatorio</small>
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col md="4">
                <validation-provider
                  #default="{ errors }"
                  name="Provincia"
                  rules="required"
                >
                  <b-form-group
                    label="Provincia"
                    label-for="provincia"
                    :state="errors.length > 0 ? false : null"
                  >
                    <v-select
                      id="provincia"
                      v-model="Quotation.provincia"
                      :disabled="Quotation.isConfirmed == 'Y' || Quotation.isConfirmed == 'R'"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="provinceList"
                      label="Provincia"
                      @input="resetPack"
                    />
                    <small
                      class="text-danger"
                    >*Campo obbligatorio</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="4">
                <validation-provider
                  #default="{ errors }"
                  name="Comune"
                  rules="required"
                >
                  <b-form-group
                    label="Comune"
                    label-for="comune"
                    :state="errors.length > 0 ? false : null"
                  >

                    <v-select
                      id="comune"
                      v-model="Quotation.comune"

                      :disabled="Quotation.isConfirmed == 'Y' || Quotation.isConfirmed == 'R'"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="comuni"
                      label="Comune"
                      :reduce="(x)=>x.Comune"
                    />
                    <small
                      class="text-danger"
                    >*Campo obbligatorio</small>
                  </b-form-group>
                </validation-provider>

              </b-col>
              <b-col md="4">
                <b-form-group
                  label="CAP"
                  label-for="indirizzo"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Cap"
                    :rules="`${controllo_conferma_documento?'required':''}`"
                  >
                    <b-form-input
                      id="cap"
                      v-model="Quotation.cap"
                      :disabled="Quotation.isConfirmed == 'Y'"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small
                      v-if="errors[0]"
                      class="text-danger"
                    >*Campo obbligatorio</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group
                  label="Sesso"
                  label-for="Sesso"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Sesso"
                  >
                    <v-select
                      id="sesso"
                      v-model="Quotation.sesso"
                      :disabled="Quotation.isConfirmed == 'Y'"
                      :options="sexList"
                      :state="errors.length > 0 ? false : null"
                      :reduce="(val) => val.Id"
                      input-id="Id"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <validation-provider
                  #default="{ errors }"
                  name="nazioneNascita"
                >
                  <b-form-group
                    label="Nazione di nascita"
                    label-for="nazioneNascita"
                    :state="errors.length > 0 ? false : null"
                  >
                    <v-select
                      id="nazioneNascita"
                      ref="nazione_nascita"
                      v-model="Quotation.nazione_nascita"
                      :disabled="Quotation.isConfirmed == 'Y'"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="countries"
                      selected="It"
                      label="name"
                      input-id="Id"
                    />
                    <b-form-invalid-feedback
                      :state="errors.length > 0 ? false : null"
                    >
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="4">
                <b-form-group
                  label="Provincia di Nascita"
                  label-for="provinciaNascita"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Provincia di Nascita"
                  >
                    <v-select
                      id="provinciaNascita"
                      v-model="Quotation.provincia_nascita"
                      :disabled="Quotation.isConfirmed == 'Y' || Quotation.nazione_nascita.Id !='It'"
                      :options="provinceList"
                      :state="errors.length > 0 ? false : null"
                      label="Provincia"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  label="Comune di Nascita"
                  label-for="ComuneNascita"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Comune di Nascita"
                  >
                    <b-form-input

                      v-if="Quotation.nazione_nascita.Id!='It'"
                      id="ComuneNascita"
                      v-model="Quotation.comune_nascita"
                      :disabled="Quotation.isConfirmed == 'Y' || Quotation.nazione_nascita.Id !='It'"
                      :state="errors.length > 0 ? false : null"
                    />
                    <v-select
                      v-if="Quotation.nazione_nascita.Id=='It'"

                      id="ComuneNascita"
                      v-model="Quotation.comune_nascita"
                      :disabled="Quotation.isConfirmed == 'Y' || Quotation.nazione_nascita.Id !='It'"
                      :state="errors.length > 0 ? false : null"
                      :options="comuni_nascita"
                      label="Comune"
                      :reduce="(x)=>x.Comune"
                    />
                    <small
                      v-if="errors[0]"
                      class="text-danger"
                    >*Campo obbligatorio</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group
                  label="Data di Nascita"
                  label-for="data-di-nascita"
                >
                  <validation-provider

                    #default="{ errors }"
                    name="Data di Nascita"
                    rules="age18"
                  >
                    <flat-pickr
                      id="data-di-nascita"
                      v-model="Quotation.data_nascita"
                      :disabled="Quotation.isConfirmed == 'Y' || Quotation.isConfirmed == 'R'"
                      class="form-control"
                      :config="{ allowInput: true, altInput: true, altFormat:'d/m/Y', enableTime: false, dateFormat: 'd/m/Y' }"
                      :state="errors.length > 0 ? false : null"
                    />
                    <b-button
                      variant="link"
                      @click="Quotation.data_nascita=''"
                    >
                      <small><feather-icon icon="XIcon" />Pulisci campo</small>
                    </b-button><br>
                    <small
                      v-if="errors[0]"
                      class="text-danger"
                    >
                      {{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </validation-observer>
        </tab-content>

        <!-- Veicolo  -->
        <tab-content
          title="Veicolo"
          :before-change="validationFormVeicolo"
        >
          <validation-observer
            ref="veicoloRules"
            tag="form"
          >
            <b-row>
              <b-col
                cols="12"
                class="mb-2"
              >
                <h5 class="mb-0">
                  Veicolo
                </h5>
                <small class="text-muted">Inserisci i dati del veicolo</small>
              </b-col>
              <b-col md="4">

                <validation-provider
                  #default="{ errors }"
                  name="Veicolo"
                  rules="required"
                >
                  <b-form-group
                    label="Tipo Veicolo"
                    label-for="tipo-veicolo"
                    :state="errors.length > 0 ? false : null"
                  >
                    <v-select
                      id="Veicolo"
                      v-model="Quotation.veicolo"
                      :disabled="Quotation.isConfirmed == 'Y' || Quotation.isConfirmed == 'R'"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="tipoVeicolo"
                      :reduce="(val) => val.Id"
                      input-id="Id"
                    />
                    <small
                      class="text-danger"
                    >*Campo obbligatorio</small>

                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col md="4">

                <validation-provider
                  #default="{ errors }"
                  name="Marca"
                  rules="required"
                >
                  <b-form-group
                    label="Marca"
                    label-for="marca"
                    :state="errors.length > 0 ? false : null"
                  >
                    <v-select
                      id="marca"
                      v-model="Quotation.marca"
                      :disabled="Quotation.isConfirmed == 'Y' || Quotation.isConfirmed == 'R'"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="marche"
                      @input="changeMarca"
                    />
                    <small
                      class="text-danger"
                    >*Campo obbligatorio</small>
                  </b-form-group>
                </validation-provider>

              </b-col>
              <b-col md="4">
                <b-form-group
                  label="Modello"
                  label-for="serie"
                >
<!--                   <validation-provider
                    #default="{ errors }"
                    name="Serie"
                    rules="required"
                  >
 -->
                  <validation-provider
                    #default="{ errors }"
                    name="Serie"
                    :rules="`${controllo_conferma_documento?'required':''}`"
                  >
<!--                     <v-select
                      id="serie"
                      v-model="Quotation.serie"
                      :disabled="Quotation.isConfirmed == 'Y'|| Quotation.isConfirmed == 'R'"
                      :state="errors.length > 0 ? false : null"
                      :options="series"
                      @input="changeSerie"
                    />
 -->
                    <v-select
                      id="serie"
                      v-model="Quotation.serie"
                      :disabled="Quotation.isConfirmed == 'Y'"
                      :state="errors.length > 0 ? false : null"
                      :options="series"
                      @input="changeSerie"
                    />

                    <small
                      v-if="errors[0]"
                      class="text-danger"
                    >*Campo obbligatorio</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  label="Versione"
                  label-for="modello"
                >

<!--                   <validation-provider
                    #default="{ errors }"
                    name="Modello"
                    rules="required"
                  >
 -->                  <validation-provider
                    #default="{ errors }"
                    name="Modello"
                    :rules="`${controllo_conferma_documento?'required':''}`"
                  >

<!--                     <v-select
                      v-if="!sbloccoModello"
                      id="modello"
                      v-model="Quotation.modello"
                      :disabled="Quotation.isConfirmed == 'Y' || Quotation.isConfirmed == 'R'"
                      :state="errors.length > 0 ? false : null"
                      :options="models"
                      @input="setDatiModelloAuto"
                    />
 -->
                    <v-select
                      v-if="!sbloccoModello"
                      id="modello"
                      v-model="Quotation.modello"
                      :disabled="Quotation.isConfirmed == 'Y'"
                      :state="errors.length > 0 ? false : null"
                      :options="models"
                      @input="setDatiModelloAuto"
                    />

                    <b-form-input
                      v-if="sbloccoModello"
                      id="modello"
                      v-model="Quotation.modello"
                      :disabled="Quotation.isConfirmed == 'Y' || Quotation.isConfirmed == 'R'"
                      :state="errors.length > 0 ? false : null"
                    />
                    <b-button
                      variant="link"
                      @click="sbloccoModello=!sbloccoModello"
                    >
                      <small><span v-if="!sbloccoModello"><feather-icon icon="UnlockIcon" /> Sblocca</span><span v-else><feather-icon icon="LockIcon" />Blocca</span> Modello</small>
                    </b-button>
                    <small
                      v-if="errors[0]"
                      class="text-danger"
                    >*Campo obbligatorio</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">

                <validation-provider
                  #default="{ errors }"
                  name="stato"
                  rules="required"
                >
                  <b-form-group
                    label="Stato veicolo"
                    label-for="stato"
                    :state="errors.length > 0 ? false : null"
                  >
                    <v-select
                      id="stato"
                      v-model="Quotation.stato_veicolo"
                      :disabled="Quotation.isConfirmed == 'Y' || Quotation.isConfirmed == 'R'"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="statoVeicolo"
                      label="text"
                      :reduce="(val) => val.Id"
                      input-id="Id"
                    />
                    <small
                      class="text-danger"
                    >*Campo obbligatorio</small>
                  </b-form-group>
                </validation-provider>

              </b-col>
              <b-col md="3">
                <b-form-group
                  label="Targa"
                  label-for="targa"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Targa"
                    :rules="`${controllo_conferma_documento?'required':''}`"
                  >
                    <b-form-input
                      id="targa"
                      v-model="Quotation.targa"
                      :disabled="Quotation.isConfirmed == 'Y'"
                      :state="errors.length > 0 ? false : null"
                      type="text"
                    />
                    <small
                      v-if="errors[0]"
                      class="text-danger"
                    >*Campo obbligatorio</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Telaio"
                  label-for="telaio"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="telaio"
                    :rules="`${controllo_conferma_documento?'required':''}`"
                  >
                    <b-form-input
                      id="telaio"
                      v-model="Quotation.telaio"
                      :disabled="Quotation.isConfirmed == 'Y'"
                      :state="errors.length > 0 ? false : null"
                      type="text"
                    />
                    <small
                      v-if="errors[0]"
                      class="text-danger"
                    >*Campo obbligatorio</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group
                  label="Data Immatricolazione"
                  label-for="data-immatricolazione"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Data Immatricolazione"
                    :rules="`${controllo_conferma_documento || controllo_data_immatricolazione ?'required':''}`"
                  >

                    <flat-pickr
                      id="data-immatricolazione"
                      v-model="Quotation.data_immatricolazione"
                      :disabled="Quotation.isConfirmed == 'Y'"
                      class="form-control"
                      :config="{ allowInput: true, altInput: true, altFormat:'d/m/Y', enableTime: false, dateFormat: 'd/m/Y', maxDate:'today'}"
                      :state="errors.length> 0 ? false : null"
                    />
                    <b-button
                      variant="link"
                      @click="Quotation.data_immatricolazione=''"
                    >
                      <small><feather-icon icon="XIcon" />Pulisci campo</small>
                    </b-button><br>
                    <small
                      v-if="errors[0]"
                      class="text-danger"
                    >
                      *Inserisci la la data di immatricolazione del veicolo</small>
                  </validation-provider>
                </b-form-group>

              </b-col>
              <b-col md="3">
                <b-form-group
                  label="Cilindrata (cm3)"
                  label-for="cilindrata"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Cilindrata"
                    :rules="`${controllo_conferma_documento?'required':''}`"
                  >
                    <b-form-input
                      id="cilindrata"
                      v-model="Quotation.cilindrata"
                      :disabled="Quotation.isConfirmed == 'Y'"
                      :state="errors.length > 0 ? false : null"
                      type="number"
                    />
                    <small
                      v-if="errors[0]"
                      class="text-danger"
                    >*Campo obbligatorio</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group
                  label="Peso (quintali)"
                  label-for="quintali"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Quintali"
                    :rules="`${controllo_conferma_documento?'required':''}`"
                  >
                    <b-form-input
                      id="quintali"
                      v-model="Quotation.quintali"
                      :disabled="Quotation.isConfirmed == 'Y'"
                      :state="errors.length > 0 ? false : null"
                      type="number"
                    />
                    <small
                      v-if="errors[0]"
                      class="text-danger"
                    >*Campo obbligatorio</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="3">
                <b-form-group
                  label="Cavalli fiscali"
                  label-for="cavalli"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Cavalli"
                    :rules="`${controllo_conferma_documento?'required':''}`"
                  >
                    <b-form-input
                      id="cavalli"
                      v-model="Quotation.cavalli"
                      :disabled="Quotation.isConfirmed == 'Y'"
                      :state="errors.length > 0 ? false : null"
                      type="number"
                    />
                    <small
                      v-if="errors[0]"
                      class="text-danger"
                    >*Campo obbligatorio</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="3">
                <b-form-group
                  label="Kw"
                  label-for="kw"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Kw"
                    :rules="`${controllo_conferma_documento?'required':''}`"
                  >
                    <b-form-input
                      id="kw"
                      v-model="Quotation.kw"
                      :disabled="Quotation.isConfirmed == 'Y'"
                      :state="errors.length > 0 ? false : null"
                      type="number"
                    />
                    <small
                      v-if="errors[0]"
                      class="text-danger"
                    >*Campo obbligatorio</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="3">
                <b-form-group
                  label="Colore"
                  label-for="colore-veicolo"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Colore Veicolo"
                    :rules="`${controllo_conferma_documento?'required':''}`"
                  >
                    <b-form-input
                      id="colore-veicolo"
                      v-model="Quotation.colore_veicolo"
                      :disabled="Quotation.isConfirmed == 'Y'"
                      :state="errors.length > 0 ? false : null"
                      type="text"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="3">

                <validation-provider
                  #default="{ errors }"
                  name="Valore"
                  rules="required"
                >
                  <b-form-group
                    label="Valore"
                    label-for="valore"
                    :state="errors.length > 0 ? false : null"
                  >
                    <b-input-group
                      prepend="€"
                      class="input-group-merge"
                      :state="errors.length > 0 ? false : null"
                    >
                      <b-form-input
                        id="valore"
                        v-model="Quotation.valore"
                        :disabled="Quotation.isConfirmed == 'Y' || Quotation.isConfirmed == 'R'"
                        :state="errors.length > 0 ? false : null"
                        type="number"
                      />
                    </b-input-group>
                    <small
                      class="text-danger"
                    >*Campo obbligatorio</small>

                  </b-form-group>
                </validation-provider>
              </b-col>


              <!--
              <b-col md="*">
                <b-form-group
                  label="Antifurto"
                  label-for="Antifurto"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Antifurto"
                  >
                    <b-form-checkbox
                      id="antifurto"
                      v-model="Quotation.antifurto"
                      :disabled="Quotation.isConfirmed == 'Y'"
                      name="antifurto"
                      value="Y"
                      unchecked-value="N"
                      switch
                      inline
                    >
                      <span v-if="Quotation.antifurto == 'Y'">Si</span>
                      <span v-else>No</span>
                    </b-form-checkbox>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              -->
              <b-col md="4">
                <b-form-group
                  label="Numero localizzatore satellitare Ghost"
                  label-for="dispositivo-localizzatore"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Numero localizzatore satellitare Ghost"
                    :rules="`${(controllo_conferma_documento && Quotation.secondaryPackages.some(x => x.Name.toLowerCase().includes('viasat')))?'required':''}`"
                  >
                    <b-form-input
                      id="dispositivo-localizzatore"
                      v-model="Quotation.localizzatore"
                      :disabled="Quotation.isConfirmed == 'Y'"
                      :state="errors.length > 0 ? false : null"
                      type="text"
                    />
                    <small
                      v-if="errors[0]"
                      class="text-danger"
                    >*Campo obbligatorio</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  label="Leasing"
                  label-for="leasing"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Leasing"
                  >
                    <b-form-checkbox
                      id="leasing"
                      v-model="Quotation.leasing"
                      :disabled="Quotation.isConfirmed == 'Y'"
                      :checked="Quotation.leasing"
                      name="leasing"
                      value="Y"
                      unchecked-value="N"
                      switch
                      inline
                    >
                      <span v-if="Quotation.leasing == 'Y'">Si</span>
                      <span v-else>No</span>
                    </b-form-checkbox>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="4">
                <b-form-group
                  label="Tipo d'uso"
                  label-for="tipo-uso"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Tipo d'uso"
                    :rules="`${controllo_conferma_documento?'required':''}`"
                  >
                    <v-select
                      id="tipo-uso"
                      v-model="Quotation.utilizzo"
                      :disabled="Quotation.isConfirmed == 'Y'"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="tipoUso"
                    />
                    <small
                      v-if="errors[0]"
                      class="text-danger"
                    >*Campo obbligatorio</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <hr v-if="Quotation.leasing == 'Y'">
            <b-row v-if="Quotation.leasing == 'Y'">
              <b-col
                cols="12"
                class="mb-2"
              >
                <h5 class="mb-0">
                  Leasing
                </h5>
                <small class="text-muted">Inserisci i dati del veicolo</small>
              </b-col>
              <b-col md="4">
                <b-form-group
                  label="Ragione sociale"
                  label-for="leasing_ragione_sociale"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Ragione sociale"
                    :rules="`${(controllo_conferma_documento && Quotation.leasing=='Y')?'required':''}`"
                  >
                    <b-form-input
                      id="leasing_ragione_sociale"
                      v-model="Quotation.leasing_ragione_sociale"
                      :disabled="Quotation.isConfirmed == 'Y'"
                      :state="errors.length > 0 ? false : null"
                      type="text"
                    />
                    <small
                      v-if="errors[0]"
                      class="text-danger"
                    >*Campo obbligatorio</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  label="Partita IVA"
                  label-for="leasing_partita_iva"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Partita Iva"
                    :rules="`${(controllo_conferma_documento && Quotation.leasing=='Y')?'required':''}`"
                  >
                    <b-form-input
                      id="leasing_partita_iva"
                      v-model="Quotation.leasing_partita_iva"
                      :disabled="Quotation.isConfirmed == 'Y'"
                      :state="errors.length > 0 ? false : null"
                      type="text"
                    />
                    <small
                      v-if="errors[0]"
                      class="text-danger"
                    >*Campo obbligatorio</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  label="PEC"
                  label-for="leasing_email"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="PEC"
                    :rules="`${(controllo_conferma_documento && Quotation.leasing=='Y')?'required|email':''}`"
                  >
                    <b-form-input
                      id="leasing_email"
                      v-model="Quotation.leasing_email"
                      :disabled="Quotation.isConfirmed == 'Y'"
                      :state="errors.length > 0 ? false : null"

                      type="text"
                    />
                    <small
                      v-if="errors[0]"
                      class="text-danger"
                    >*Campo obbligatorio</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  label="Telefono"
                  label-for="leasing_telefono"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Telefono"
                    :rules="`${(controllo_conferma_documento && Quotation.leasing=='Y')?'required':''}`"
                  >
                    <b-form-input
                      id="leasing_telefono"
                      v-model="Quotation.leasing_telefono"
                      :disabled="Quotation.isConfirmed == 'Y'"
                      :state="errors.length > 0 ? false : null"
                      type="text"
                    />
                    <small
                      v-if="errors[0]"
                      class="text-danger"
                    >*Campo obbligatorio</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="8">
                <b-form-group
                  label="Indirizzo"
                  label-for="lesing_indirizzo"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Indirizzo"
                    :rules="`${(controllo_conferma_documento && Quotation.leasing=='Y')?'required':''}`"
                  >
                    <b-form-input
                      id="lesing_indirizzo"
                      v-model="Quotation.lesing_indirizzo"
                      :disabled="Quotation.isConfirmed == 'Y'"
                      :state="errors.length > 0 ? false : null"
                      placeholder=""
                    />
                    <small
                      v-if="errors[0]"
                      class="text-danger"
                    >*Campo obbligatorio</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <validation-provider
                  #default="{ errors }"
                  name="Nazione"
                  :rules="`${(controllo_conferma_documento && Quotation.leasing=='Y')?'required':''}`"
                >
                  <b-form-group
                    label="Nazione"
                    label-for="leasing_nazione"
                    :state="errors.length > 0 ? false : null"
                  >
                    <b-form-invalid-feedback
                      :state="errors.length > 0 ? false : null"
                    >
                      <v-select
                        id="leasing_nazione"
                        v-model="Quotation.leasing_nazione"
                        :disabled="Quotation.isConfirmed == 'Y' || true"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="countries"
                        label="name"
                        input-id="Id"
                      />

                      <small
                        v-if="errors[0]"
                        class="text-danger"
                      >*Campo obbligatorio</small>
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="4">
                <b-form-group
                  label="Provincia"
                  label-for="leasing_provincia"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Provincia"
                    :rules="`${(controllo_conferma_documento && Quotation.leasing=='Y')?'required':''}`"
                  >

                    <v-select
                      id="leasing_provincia"
                      v-model="Quotation.leasing_provincia"
                      :disabled="Quotation.isConfirmed == 'Y'"
                      :options="provinceList"
                      :state="errors.length > 0 ? false : null"

                      label="Provincia"
                    />
                    <b-form-invalid-feedback
                      :state="errors.length > 0 ? false : null"
                    >
                      <small
                        class="text-danger"
                      >*Campo obbligatorio</small>
                    </b-form-invalid-feedback>

                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  label="Comune"
                  label-for="leasing_comune"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Comune"
                    :rules="`${(controllo_conferma_documento && Quotation.leasing=='Y')?'required':''}`"
                  >
                    <v-select
                      id="leasing_comune"
                      v-model="Quotation.leasing_comune"
                      :disabled="Quotation.isConfirmed == 'Y' || Quotation.leasing_nazione.Id !='It'"
                      :state="errors.length > 0 ? false : null"
                      :options="comuni_leasing"
                      label="Comune"
                      :reduce="(x)=>x.Comune"
                    />
                    <small
                      v-if="errors[0]"
                      class="text-danger"
                    >*Campo obbligatorio</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  label="CAP"
                  label-for="leasing_cap"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Cap"
                    :rules="`${(controllo_conferma_documento && Quotation.leasing=='Y')?'required':''}`"
                  >
                    <b-form-input
                      id="leasing_cap"
                      v-model="Quotation.leasing_cap"
                      :disabled="Quotation.isConfirmed == 'Y'"
                      :state="errors.length > 0 ? false : null"
                      placeholder=""
                    />
                    <small
                      v-if="errors[0]"
                      class="text-danger"
                    >*Campo obbligatorio</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group
                  label="Scadenza leasing"
                  label-for="leasing_data_scadenza"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Scadenza leasing"
                    :rules="`${(controllo_conferma_documento && Quotation.leasing=='Y')?'required':''}`"
                  >
                    <flat-pickr
                      id="leasing_data_scadenza"
                      v-model="Quotation.leasing_data_scadenza"
                      class="form-control"
                      :config="{ allowInput: true, altInput: true,altFormat:'d/m/Y', enableTime: false, dateFormat: 'd/m/Y' }"
                      :state="errors.length > 0 ? false : null"
                    /><br>
                    <small
                      v-if="errors[0]"
                      class="text-danger"
                    >*Campo obbligatorio</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group
                  label="Scadenza vincolo"
                  label-for="leasing_data_scadenza_vincolo"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Scadenza vincolo"
                    :rules="`${(controllo_conferma_documento && Quotation.leasing=='Y')?'required':''}`"
                  >
                    <flat-pickr
                      id="leasing_data_scadenza"
                      v-model="Quotation.leasing_data_scadenza_vincolo"
                      class="form-control"
                      :config="{ allowInput: true, altInput: true,altFormat:'d/m/Y', enableTime: false, dateFormat: 'd/m/Y' }"
                      :state="errors.length > 0 ? false : null"
                    /><br>
                    <small
                      v-if="errors[0]"
                      class="text-danger"
                    >*Campo obbligatorio</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </validation-observer>
        </tab-content>

        <tab-content
          title="Polizza"
          :before-change="validationFormPolizza"
        >  <validation-observer
          ref="polizzaRules"
          tag="form"
        >
          <b-row>
            <b-col
              cols="12"
              class="mb-2"
            >
              <h5 class="mb-0">
                Polizza
              </h5>
              <small class="text-muted">Configura la polizza</small>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              md="2"
              offset="2"
            >

              <validation-provider
                #default="{ errors }"
                name="copertura"
                rules="required"
              >
                <b-form-group
                  label="Durata Copertura"
                  label-for="copertura"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    id="copertura"
                    v-model="Quotation.durata_copertura"
                    :disabled="Quotation.isConfirmed == 'Y' || Quotation.isConfirmed == 'R'"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="copertura"
                    @input="calcoloCoperturaDateCF"
                  />
                  <small
                    class="text-danger"
                  >*Campo obbligatorio</small>
                </b-form-group>
              </validation-provider>

            </b-col>
            <b-col md="3">

              <validation-provider
                #default="{ errors }"
                name="Inizio Copertura"
                :rules="`${controllo_conferma_documento?'required':''}`"
              >
                <b-form-group
                  label="Inizio Copertura"
                  label-for="inizio-copertura"
                  :state="errors.length > 0 ? false : null"
                >
                  <b-form-input
                    v-if="Quotation.isConfirmed == 'Y'"
                    :value="Quotation.inizio_copertura"
                    disabled
                  />
                  <flat-pickr
                    v-else
                    id="inizio-copertura"
                    v-model="Quotation.inizio_copertura"
                    :disabled="Quotation.isConfirmed == 'Y'"
                    class="form-control"
                    :config="config_data_inizio_copertura"
                    :state="errors.length > 0 ? false : null"
                    @input="calcoloCoperturaDate"
                  />
                  <b-button
                    variant="link"
                    @click="Quotation.inizio_copertura=''"
                  >
                    <small><feather-icon icon="XIcon" />Pulisci campo</small>
                  </b-button><br>
                  <small
                    v-if="errors[0]"
                    class="text-danger"
                  >{{
                    errors[0]
                  }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="3">
              <b-form-group
                label="Fine Copertura"
                label-for="fine-copertura"
              >
                <b-form-input
                  :value="Quotation.fine_copertura"
                  disabled
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">

              <validation-provider
                #default="{ errors }"
                name="Programma"
                rules="required"
              >
                <b-form-group
                  label="Programma Assicurativo"
                  label-for="programma"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    id="program"
                    v-model="Quotation.program"
                    :disabled="Quotation.isConfirmed == 'Y' || Quotation.isConfirmed == 'R'"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="programList"
                    label="Name"
                    input-id="Id"
                    @input="changeProgram"
                  />
                  <small
                    class="text-danger"
                  >*Campo obbligatorio</small>

                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Prodotto"
                label-for="prodotto"
              >
                {{ product }}
              </b-form-group>
            </b-col>
            <b-col md="6">

              <validation-provider
                #default="{ errors }"
                name="Pacchetto"
                rules="required"
              >
                <b-form-group
                  label="Pacchetto"
                  label-for="pacchetto"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    v-if="basePackageList"
                    id="pacchetto"
                    v-model="Quotation.package"
                    :disabled="Quotation.isConfirmed == 'Y' || Quotation.isConfirmed == 'R'"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="basePackageList"
                    label="Name"
                    @input="changeBasePackage"
                  />
                  <small
                    class="text-danger"
                  >*Campo obbligatorio</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <hr v-if="secondaryProducts">
          <b-row v-if="secondaryProducts">
            <b-col md="12">
              <h5>Prodotti Collegati</h5>
              <small
                class="text-muted"
              >Prodotti complementari alla polizza</small>
            </b-col>
          </b-row>

          <b-row
            v-if="secondaryProducts"
            :key="Quotation.secondaryPackages"
          >
            <b-col
              v-for="itm in secondaryProducts"
              :key="itm.Id"
            >
              <h5>{{ itm.product }}</h5>
              <b-form-checkbox
                v-for="i in itm.packages"
                :key="i.item.Id"
                v-model="Quotation.secondaryPackages"
                :disabled="(Quotation.isConfirmed == 'Y' || Quotation.isConfirmed == 'R') || i.disabled "
                :value="i.item || i.mandatory || i.selected"
                class="mr-0 mt-50"
                name="is-rtl"
                switch
                inline
                @input="changeBasePackage"
              >
                {{ i.item.Name }}
              </b-form-checkbox>
              <b-row v-if="itm.product.toLowerCase().includes('gap') && Quotation.secondaryPackages.some(x=> x.Id== itm.packages[0].item.Id)">
                <b-col>
                  <validation-provider
                    #default="{ errors }"
                    name="copertura"
                    rules="required"
                  >

                    <b-form-group
                      label="Durata Copertura GAP"
                      label-for="copertura"
                    >
                      <small
                        v-if="itm.packages[0].item.Name.toLowerCase().includes('premium') && Quotation.durata_copertura < 36"
                        class="text-danger"
                      >** Per attivare la GAP la durata della polizza deve essere superiore o uguale a 36 mesi</small>
                      <small
                        v-else-if="Quotation.durata_copertura < 24"
                        class="text-danger"
                      >** Per attivare la GAP la durata della polizza deve essere superiore o uguale a 24 mesi</small>
                      <v-select
                        id="copertura_gap_premium"
                        v-model="Quotation.gap_durata_copertura"
                        :disabled="Quotation.isConfirmed == 'Y' || Quotation.isConfirmed == 'R' || Quotation.durata_copertura < 24 ||
                          (itm.product.toLowerCase().includes('premium') && Quotation.durata_copertura < 36 )
                        "
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="calcoloCoperturaGAP(itm.packages[0].item.Name)"
                        @input="calcoloCoperturaDateGAP"
                      />
                      <small
                        class="text-danger"
                      >*Campo obbligatorio</small>
                    </b-form-group>

                  </validation-provider>

                </b-col>
              </b-row>

            </b-col></b-row>


          <hr>
          <b-row>

            <b-col md="6">
              <b-form-group
                label="Metodo di pagamento"
                label-for="metpodo_di_pagamento"
              >
                <validation-provider
                  #default="{ errors }"
                  name="metpodo_di_pagamento"
                  :rules="`${controllo_conferma_documento?'required':''}`"
                >
                  <v-select
                    id="metpodo_di_pagamento"
                    v-model="Quotation.metodo_di_pagamento"
                    :disabled="Quotation.isConfirmed == 'Y'"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="['Contanti','Bonifico Bancario','Carta di credito','Assegno']"
                  />
                  <small
                    v-if="errors[0]"
                    class="text-danger"
                  >Seleziona un  metodo di pagamento</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
        </tab-content>

        <template
          slot="finish"
          slot-scope="props"
        >
          <wizard-button
            :disabled="Quotation.isConfirmed == 'Y'"
            :class="(controllo_conferma_documento)?'btn-success':'finish-button'"
            :style="props.fillButtonStyle"
          >
            <span v-if="controllo_conferma_documento">
              Attiva Preventivo
            </span>
            <span v-else>
              Salva e Calcola Preventivo
            </span>
          </wizard-button>
        </template>

        <template
          slot="prev"
          slot-scope="props"
        >
          <wizard-button
            class="wizard-btn"
          >
            Precedente
          </wizard-button>
        </template>
        <template
          slot="next"
          slot-scope="props"
        >
          <wizard-button
            class="wizard-footer-right"
            :style="props.fillButtonStyle"
          >
            Avanti
          </wizard-button>
        </template>
      </form-wizard>
    </b-card>

    <b-modal
      id="static-file-view-modal"
      title="Documenti"
      ok-only
      ok-title="Chiudi"
    >

      <Attachments-list
        :product-list="attachments_list"
      />
      <dynamic-list
        v-if="Quotation.isConfirmed=='Y'"
        :quotation-id="Quotation.Id"
      />
    </b-modal>
  </b-overlay>
</template>

<script>
import "vue-form-wizard/dist/vue-form-wizard.min.css"
import {
  FormWizard, TabContent, WizardButton,
} from "vue-form-wizard"
import {
  ValidationProvider,
  ValidationObserver,

  validate,
  extend,
} from "vee-validate"
import { email, required } from '@validations'

import flatPickr from "vue-flatpickr-component"
import {
  BRow,
  BCol,
  BFormGroup,
  BForm,
  BButton,
  BCard,
  BOverlay,
  BFormTextarea,
  BFormInput,
  BFormInvalidFeedback,
  BFormCheckbox,
  BInputGroup,
  VBModal,
} from "bootstrap-vue"
// eslint-disable-next-line import/no-cycle

// eslint-disable-next-line import/no-cycle
import { getUserData } from "@/auth/utils"
import Ripple from "vue-ripple-directive"
import { ref, computed, onUnmounted } from "@vue/composition-api"
// eslint-disable-next-line import/no-cycle
import { useRouter } from "@core/utils/utils"
import vSelect from "vue-select"
import store from "@/store"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"

import { format, parse, formatDistance } from "date-fns"
import quotationStoreModule from "./quotesStoreModule"
import programStoreModule from "../Programs/programStoreModule"
// import PackageView from './PackageView.vue'
import zonaStoreModule from "../Zona/zonaStoreModule"
import AttachmentsList from "../../base/AttachmentsList.vue"
import DynamicList from "./DynamicList.vue"
import agencyStoreModule from "../Agency/agencyStoreModule"

extend('age18', {
  message: "Il cliente ha meno di 18 anni",
  validate(value, args) {
    if (!value) return true
    let min = new Date()
    min = min.setFullYear(min.getFullYear() - 18, min.getMonth(), min.getDate())
    const date = parse(value, 'dd/MM/yyyy', new Date())
    return (min - date) >= 0
  },
  params: ['controllo_bozza_documento', 'controllo_conferma_documento'],
})
extend('CF', {
  message: "Il Codice Fiscare deve essere lungo 16 caratteri o 11 caratteri per le societa'",
  validate(value, args) {
    if (!value) return false
    return value.length == 16 || value.length == 11
  },
})

function initialData() {
  return {
    agency: {},
    attachments_list: [],
    routerParam: {},
    fileList: [],
    userData: {},
    showOverlay: true,
    showModalCalcolaPreventivo: false,
    config_data_inizio_copertura: {
      enableTime: false, dateFormat: 'd/m/Y', minDate: 'today', allowInput: true, altInput: true, altFormat: 'd/m/Y',
    },
    Quotation: {
      nazione: { Id: "It", name: "Italia" },
      nazione_nascita: { Id: "It", name: "Italia" },
      leasing_nazione: { Id: "It", name: "Italia" },
      comune: null,
      marca: null,
      cap: null,
      program: null,
      basePackage: null,
      secondaryPackages: [],
      secondaryProducts: [],
      stato_polizza: "Bozza",
      data_stampa: new Date(),
      durata_copertura: 12,
      inizio_copertura: null, // format(new Date(), 'dd/MM/yyyy'),
      fine_copertura: "",
      serie: null,
      leasing: 'N',
      gap_durata_copertura: null,
      gap_inizio_copertura: null, // format(new Date(), 'dd/MM/yyyy'),
      gap_fine_copertura: null, // format(new Date(), 'dd/MM/yyyy'),
    },
    provvigione: "******",
    programList: [],
    baseProduct: null,
    secondaryProducts: [],
    product: "",
    sexList: [
      { label: "Maschio", Id: "M" },
      { label: "Femmina", Id: "F" },
    ],

    copertura: ["12", "24", "36", "48", "60"],
    copertura_gap: ["12", "24", "36", "48"],
    copertura_gap_premium: ["12", "24", "36", "48"],
    basePackageList: [],
    tipoUso: ["Conto Terzi", "Noleggio", "Privato", "Uso Proprio", "Trasporto cose conto proprio", "Trasporto cose conto terzi"],
    statoVeicolo: [
      { Id: "N", text: "Nuovo" },
      { Id: "U", text: "Usato" },
    ],
    comuni: [],
    comuni_nascita: [],
    comuni_leasing: [],
    province_nascita: [],
    marche: [],
    series: [],
    models: [],
    modelsWhitDati: [],
    tipoVeicolo: [
      { label: "Autovettura", Id: "A" },
      { label: "Autocarro", Id: "C" },
    ],
    provinceList: [],
    countries: [],
    Prodotto: {},
    sbloccoModello: false,
    controllo_data_immatricolazione: 'required',
    controllo_bozza_documento: true,
    controllo_conferma_documento: false,
  }
}

export default {
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    // BForm,
    BButton,
    // BFormTextarea,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    FormWizard,
    TabContent,
    BFormCheckbox,
    flatPickr,
    BInputGroup,
    WizardButton,
    BFormInvalidFeedback,
    BOverlay,
    // PackageView,
    // required,
    // VBModal,
    AttachmentsList,
    DynamicList,
  },
  data() {
    return initialData()
  },
  /*
   "Quotation.data_immatricolazione": {
      handler(val, oldVal) {
        if (this.Quotation.data_immatricolazione
          && this.Quotation.data_immatricolazione.toString() != "Invalid Date") {
          a = () => {
            const testDate=this.Quotation.fine_copertura.setFullYear(this.Quotation.fine_copertura.getFullwatYear()-10)
            if (testDate > this.Quotation.data_immatricolazione)

            }
          }
      },
    },


  */

  watch: {
    "Quotation.valore": {
      handler(val, oldVal) {
        if (this.Quotation.isConfirmed == 'Y') return
        this.changeBasePackage()
      },
    },
    "Quotation.stato_veicolo": {
      handler(val, oldVal) {
        if (!val) return
        if (this.Quotation.isConfirmed == 'Y') return
        if (val == 'N') {
          this.controllo_data_immatricolazione = ''
        } else { this.controllo_data_immatricolazione = 'required' }
      },
    },

    "Quotation.leasing_provincia": {
      handler(val, oldVal) {
        try {
          if (this.Quotation.isConfirmed == 'Y') return
          this.loadComuniByProvinciaLeasing()
          // this.Quotation.cap = null
        } catch (err) {}
      },
    },
    "Quotation.leasing_comune": {
      handler(val, oldVal) {
        try {
          if (this.Quotation.isConfirmed == 'Y') return
          if (this.Quotation.leasing_comune && this.comuni_leasing.length > 0) {
            const c = this.comuni_leasing.find(x => x.Comune == this.Quotation.leasing_comune)
            if (c.CAP) { this.Quotation.leasing_cap = c.CAP }
          }
          /*
          if (val) {
            if (!this.Quotation.cap || this.Quotation?.cap === '') this.Quotation.cap = val.CAP
          }
          */
        } catch (err) { console.log('comune-err', err) }
      },
    },
    "Quotation.comune": {
      handler(val, oldVal) {
        try {
          if (this.Quotation.isConfirmed == 'Y') return
          if (this.Quotation.comune && this.comuni.length > 0) {
            const c = this.comuni.find(x => x.Comune == this.Quotation.comune)
            if (c.CAP) { this.Quotation.cap = c.CAP }
          }
          /*
          if (val) {
            if (!this.Quotation.cap || this.Quotation?.cap === '') this.Quotation.cap = val.CAP
          }
          */
        } catch (err) { console.log('comune-err', err) }
      },
    },
    "Quotation.provincia": {
      handler(val, oldVal) {
        try {
          if (this.Quotation.isConfirmed == 'Y') return
          this.loadComuniByProvincia()
          // this.Quotation.cap = null
        // eslint-disable-next-line no-empty
        } catch (err) {}
      },
    },
    /*
    "Quotation.comune": {
      handler(val, oldVal) {
        try {
          if (this.Quotation.comune) {
            const c = this.comuni.find(x => x.Comune == this.Quotation.comune)
            console.log("CaP-c", c)
            if (c.CAP) { this.Quotation.cap = c.CAP }
            console.log("CaP", this.Quotation.cap)
          }
          /*
          if (val) {
            if (!this.Quotation.cap || this.Quotation?.cap === '') this.Quotation.cap = val.CAP
          }
          */
    /*    } catch (err) { console.log('comune-err', err) }
      },
    },
    */
    "Quotation.nazione_nascita": {
      handler(val, oldVal) {
        try {
          if (this.Quotation.isConfirmed == 'Y') return
          if (this.Quotation.nazione_nascita.Id != 'It') {
            this.Quotation.provincia_nascita = "EE"
          } else {
            // this.loadProvinceNascita()
          }
        } catch (err) { console.log('comune-err', err) }
      },
    },
    "Quotation.provincia_nascita": {
      handler(val, oldVal) {
        try {
          if (this.Quotation.isConfirmed == 'Y') return
          this.loadComuniByProvinciaNascita()
          /*
          if (val) {
            if (!this.Quotation.cap || this.Quotation?.cap === '') this.Quotation.cap = val.CAP
          }
          */
        } catch (err) { console.log('comune-err', err) }
      },
    },
    "Quotation.marca": {
      handler(val, oldVal) {
        try {
          if (this.Quotation.isConfirmed == 'Y') return
          this.fetchSerie()
        } catch (err) { console.log('comune-err', err) }
      },
    },
    'Quotation.serie': {
      handler(val, oldVal) {
        try {
          if (this.Quotation.isConfirmed == 'Y') return
          this.fetchModel()
        } catch (err) { console.log('comune-err', err) }
      },
    },
    "$route.params": {
      handler(val, old) {
        try {
          if (old == undefined) return
          if (val !== old) {
            Object.assign(this.$data, initialData())
            this.initComponent()
          }
        } catch (error) {
          console.log(error)
        }
      },
      deep: true,
      immediate: true,
    },
  },

  mounted() {
    this.initComponent()
  },
  methods: {
    /*
    isFieldDisabled(statusToCheck) { // Per default il controllo su isConfirmed=="Y" viene sempre fatto
      // return (this.Quotation.isConfirmed == 'Y' || (this.controllo_conferma_documento && this.Quotation.isConfirmed == 'R'))
      if (typeof statusToCheck === "undefined") {
        statusToCheck = "--"
      }
      return this.Quotation.isConfirmed == 'Y' || this.Quotation.isConfirmed == statusToCheck
    },
    */
    resetPack(oldValue, newValue) {
      if (oldValue != newValue) {
        this.Quotation.package = null
        this.Quotation.secondaryPackages = []
      }
    },
    calcoloCoperturaGAP(itm) {
      // const isPremium = this.Quotation.secondaryPackages.some(x => x.Name.toLowerCase().includes('premium'))
      const isPremium = itm.toLowerCase().includes('premium')
      const c = this.Quotation.durata_copertura
      const index = this.copertura.indexOf(c.toString())
      if (isPremium) return this.copertura.slice(0, index - 1)
      return this.copertura.slice(0, index)
    },
    controlloViasatMM(elem) {
      const province = ['FG', 'BT', 'BA', 'TA', 'BR', 'LE', 'AV', 'BN', 'CE', 'NA', 'SA', 'CZ', 'CS', 'KR', 'RC', 'VV', 'CT']
      const isValore = this.Quotation.valore > 50000
      const isProvincia = province.some(x => x.includes(this.Quotation.provincia.Sigla))
      if (isValore || isProvincia) {
        elem.selected = true
        elem.mandatory = true
        elem.disabled = true
      }
      return elem
    },
    controlloViasatPremium(elem) {
      const province = ['FG', 'BT', 'BA', 'TA', 'BR', 'LE', 'AV', 'BN', 'CE', 'NA', 'SA', 'CZ', 'CS', 'KR', 'RC', 'VV', 'CT']
      const isValore = this.Quotation.valore > 100000
      const isProvincia = province.some(x => x.includes(this.Quotation.provincia.Sigla))
      if (isValore || isProvincia) {
        elem.selected = true
        elem.mandatory = true
        elem.disabled = true
      }
      return elem
    },

    controlloIMAMM(elem) {
      // const isViaSat = this.Quotation.secondaryPackages.some(x => x.Name.toLowerCase().includes('viasat'))
      // elem = this.controlloViasatMM(elem)

      // if (isViaSat) {
      //  elem.mandatory = true
      //  elem.selected = true
      // }
      return elem
    },
    controlloIMAPremium(elem) {
      // const isViaSat = this.Quotation.secondaryPackages.some(x => x.Name.toLowerCase().includes('viasat'))
      // elem = this.controlloViasatPremium(elem)
      // if (isViaSat) {
      //  elem.mandatory = true
      //  elem.selected = true
      // }
      return elem
    },
    controlloFranchigia(elem) {
      // const isViaSat = this.Quotation.secondaryPackages.some(x => x.Name.toLowerCase().includes('viasat'))
      // elem = this.controlloViasatPremium(elem)
      // if (isViaSat) {
      //  elem.mandatory = true
      //  elem.selected = true
      // }
      return elem
    },
    controlloGAPMM(elem) {
      elem.selected = false
      elem.disabled = true
      const isDurataMinima = parseInt(this.Quotation.durata_copertura) >= 24
      const isValore = (parseInt(this.Quotation.valore) >= 3500 && parseInt(this.Quotation.valore) <= 150000)
      if (isDurataMinima && isValore) {
        elem.disabled = false
      } else {
        elem.disabled = true
        if (this.Quotation.secondaryPackages.some(x => x.Id == elem.item.Id)) {
          const index = this.Quotation.secondaryPackages.findIndex(x => x.Id == elem.item.Id)
          if (index >= 0) { this.Quotation.secondaryPackages.splice(index, 1) }
        }
      }
      return elem
    },
    controlloGAPPremium(elem) {
      elem.selected = false
      elem.disabled = true
      const isDurataMinima = parseInt(this.Quotation.durata_copertura) >= 36
      const isValore = (parseInt(this.Quotation.valore) >= 3500 && parseInt(this.Quotation.valore) <= 150000)
      if (isDurataMinima && isValore) {
        elem.disabled = false
      } else {
        elem.disabled = true
        if (this.Quotation.secondaryPackages.some(x => x.Id == elem.item.Id)) {
          const index = this.Quotation.secondaryPackages.findIndex(x => x.Id == elem.item.Id)
          if (index >= 0) { this.Quotation.secondaryPackages.splice(index, 1) }
        }
      }


      return elem
    },
    controlloRegoleBusiness() {
      // if CVT PREMIUM
      if (this.Quotation.program.Name.toLowerCase().includes('premium')) { return this.controlloRegoleBusinessCVTP() }
      // if CVT MM
      return this.controlloRegoleBusinessCVTMM()
    },
    controlloRegoleBusinessCVTMM() {
      const marchiCVTPremium = ['jaguar', 'land rover', 'bmw', 'porsche', 'audi', 'volkswagen', 'mercedes', 'vw', 'volkswagen']
      const noMarchi = ['aston martin', 'audi', 'bentley', 'bmw', 'bugatti', 'cadillac', 'jaguar', 'land rover', 'ferrari', 'lamborghini', 'lotus', 'mclaren', 'mercedes', 'porsche', 'royce', 'volkswagen', 'vw']
      const marchiNoNuovi = ['kia', 'hyundai', 'tesla']
      const isNuovo = this.Quotation.stato_veicolo == 'N'
      const isKasko = this.Quotation.package.Name.toLowerCase().includes("kasko")
      const isOverMoneyLimit = this.Quotation.valore > 190000
      const isOverWeightLimit = this.Quotation.quintali > 35
      const isAutocarro = this.Quotation.veicolo == 'C'

      //
      // criterio marchi Premium
      //
      const isPremium = marchiCVTPremium.some(x => this.Quotation.marca.toLowerCase().includes(x.toLowerCase()) && !this.Quotation.marca.toLowerCase().includes('skoda'))
      if (isPremium) {
        this.$swal({
          icon: "warning",
          title: "Attenzione!",
          text: "Il marchio selezionato rientra nel programma Acrisure Genertel Premium, selezionare il programma corretto",
          customClass: {
            confirmButton: "btn btn-secondary",
          },
        })
        return false
      }
      //
      // criterio marchi
      //
      const isNoMarche = noMarchi.some(x => this.Quotation.marca.toLowerCase().includes(x.toLowerCase()) && !this.Quotation.marca.toLowerCase().includes('skoda'))
      if (isNoMarche) {
        this.$swal({
          icon: "warning",
          title: "Attenzione!",
          text: "Il marchio indicato non rientra nei marchi assicurabili",
          customClass: {
            confirmButton: "btn btn-secondary",
          },
        })
        return false
      }
      //
      // kia, hyundai, tesla, se nuovo bloccare
      //
      const isMarcheNoNuovi = marchiNoNuovi.some(x => this.Quotation.marca.toLowerCase().includes(x.toLowerCase()))
      console.log('marchiNoNuovi', marchiNoNuovi)
      console.log('this.Quotation.marca', this.Quotation.marca)
      console.log('isMarcheNoNuovi', isMarcheNoNuovi)
      console.log('isMarcheNoNuovi', isNuovo)

      if (isMarcheNoNuovi && isNuovo) {
        this.$swal({
          icon: "warning",
          title: "Attenzione!",
          text: "Questo marchio è assicurabile solo se il veicolo è usato",
          customClass: {
            confirmButton: "btn btn-secondary",
          },
        })
        return false
      }
      //
      // constrollo se il pacchetto kasko è selezionato
      //
      if (!isNuovo && isKasko) {
        this.$swal({
          icon: "warning",
          title: "Attenzione!",
          text: "Per i veicoli usati le opzioni KASKO non sono abilitate",
          customClass: {
            confirmButton: "btn btn-secondary",
          },
        })
        return false
      }
      //
      // constrollo se età > 10
      //
      if (!this.Quotation.fine_copertura
        || this.Quotation?.fine_copertura.toString() == "Invalid Date"
      || this.Quotation?.fine_copertura.toString() == ''
      ) {
        const dataInizio = new Date()

        let mesi = dataInizio.getMonth()
        mesi += parseInt(this.Quotation.durata_copertura, 10)
        const dataFine = format(dataInizio.setMonth(mesi), "dd/MM/yyyy")
        this.Quotation.fine_copertura = dataFine
      }

      if (this.Quotation.data_immatricolazione
        && this.Quotation.fine_copertura
        && this.Quotation.data_immatricolazione.toString() != "Invalid Date"
        && this.Quotation.fine_copertura.toString() != "Invalid Date") {
        const dataImmatricolazione = parse(this.Quotation.data_immatricolazione, "dd/MM/yyyy", new Date())
        const dataFineCopertura = parse(this.Quotation.fine_copertura, "dd/MM/yyyy", new Date())


        const testDate = new Date(dataFineCopertura.setFullYear(dataFineCopertura.getFullYear() - 10))
        const isETAMore10 = testDate > dataImmatricolazione
        if (isETAMore10) {
          this.$swal({
            icon: "warning",
            title: "Attenzione!",
            text: "L'anzianità del veicolo supera i 10 anni",
            customClass: {
              confirmButton: "btn btn-secondary",
            },
          })
          return false
        }
      }
      //
      // constrollo valorea uto supera> 190K
      //
      if (isOverMoneyLimit) {
        this.$swal({
          icon: "warning",
          title: "Attenzione!",
          text: "Il valore del veicolo supera la soglia assicurabile",
          customClass: {
            confirmButton: "btn btn-secondary",
          },
        })
        return false
      }
      //
      // constrollo quintali
      //
      if (isOverWeightLimit && isAutocarro) {
        this.$swal({
          icon: "warning",
          title: "Attenzione!",
          text: "Il peso dell'autocarro supera i 35 quintali",
          customClass: {
            confirmButton: "btn btn-secondary",
          },
        })
        return false
      }
      //
      // controllo durata gap> durata polizza
      //
      if (this.Quotation.fine_copertura
        && this.Quotation.gap_fine_copertura
        && this.Quotation.gap_fine_copertura.toString() != "Invalid Date"
        && this.Quotation.fine_copertura.toString() != "Invalid Date") {
        const dataFinePolizza = parse(this.Quotation.fine_copertura, "dd/MM/yyyy", new Date())
        const dataFineGAP = parse(this.Quotation.gap_fine_copertura, "dd/MM/yyyy", new Date())
        if (dataFineGAP > dataFinePolizza) {
          this.$swal({
            icon: "warning",
            title: "Attenzione!",
            text: "La data Fine GAP è superiore al termine della polizza, controllare la durata della GAP",
            customClass: {
              confirmButton: "btn btn-secondary",
            },
          })
          return false
        }
      }
      return true
    },

    controlloRegoleBusinessCVTP() {
      const noMarchiCVTMM = ['aston martin', 'audi', 'bentley', 'bmw', 'bugatti', 'cadillac', 'jaguar', 'land rover', 'ferrari', 'lamborghini', 'lotus', 'mclaren', 'mercedes', 'porsche', 'royce', 'volkswagen', 'vw']
      const onlyMarchi = ['jaguar', 'land rover', 'bmw', 'porsche', 'audi', 'volkswagen', 'mercedes', 'vw']
      const marchiNoNuovi = ['mercedes']
      const isNuovo = this.Quotation.stato_veicolo == 'N'
      const isKasko = this.Quotation.package.Name.toLowerCase().includes("kasko")
      const isOverMoneyLimit = this.Quotation.valore > 250000
      const isOverWeightLimit = this.Quotation.quintali > 35
      const isAutocarro = this.Quotation.veicolo == 'C'
      const isGAPValore = this.Quotation.valore >= 3500 && this.Quotation.valore <= 150000

      if (!isGAPValore && this.Quotation.secondaryPackages.some(x => x.Name.toLowerCase().includes('protezione'))) {
        this.$swal({
          icon: "warning",
          title: "Attenzione!",
          text: "Impossibile attivare la GAP. Il valore del veicolo deve essere compreso tra 3500 e 150000",
          customClass: {
            confirmButton: "btn btn-secondary",
          },
        })
        return false
      }


      //
      // criterio marchi
      //
      const isMarche = onlyMarchi.some(x => this.Quotation.marca.toLowerCase().includes(x.toLowerCase()) && !this.Quotation.marca.toLowerCase().includes('skoda'))
      const isNoMarcheCVTMM = !noMarchiCVTMM.some(x => this.Quotation.marca.toLowerCase().includes(x.toLowerCase()) && !this.Quotation.marca.toLowerCase().includes('skoda'))
      if (!isMarche) {
        if (isNoMarcheCVTMM) {
          this.$swal({
            icon: "warning",
            title: "Attenzione!",
            text: "Il marchio selezionato rientra nel programma Acrisure Genertel, selezionare il programma corretto",
            customClass: {
              confirmButton: "btn btn-secondary",
            },
          })
        } else {
          this.$swal({
            icon: "warning",
            title: "Attenzione!",
            text: "Il marchio selezionato non rientra nei marchi assicurabili",
            customClass: {
              confirmButton: "btn btn-secondary",
            },
          })
        }
        return false
      }
      //
      // mercedes, se nuovo bloccare
      //
      const isMarcheNoNuovi = marchiNoNuovi.some(x => this.Quotation.marca.toLowerCase().includes(x.toLowerCase()))
      if (isMarcheNoNuovi && isNuovo) {
        this.$swal({
          icon: "warning",
          title: "Attenzione!",
          text: "Questo marchio è assicurabile solo se il veicolo è usato",
          customClass: {
            confirmButton: "btn btn-secondary",
          },
        })
        return false
      }
      //
      // constrollo se il pacchetto kasko è selezionato
      //
      if (!isNuovo && isKasko) {
        this.$swal({
          icon: "warning",
          title: "Attenzione!",
          text: "Per i veicoli usati le opzioni KASKO non sono abilitate",
          customClass: {
            confirmButton: "btn btn-secondary",
          },
        })
        return false
      }
      //
      // constrollo se età > 10
      //
      if (!this.Quotation.fine_copertura
        || this.Quotation?.fine_copertura.toString() == "Invalid Date"
      || this.Quotation?.fine_copertura.toString() == ''
      ) {
        const dataInizio = new Date()

        let mesi = dataInizio.getMonth()
        mesi += parseInt(this.Quotation.durata_copertura, 10)
        const dataFine = format(dataInizio.setMonth(mesi), "dd/MM/yyyy")
        this.Quotation.fine_copertura = dataFine
      }


      if (this.Quotation.data_immatricolazione
        && this.Quotation.fine_copertura
        && this.Quotation.data_immatricolazione.toString() != "Invalid Date"
        && this.Quotation.fine_copertura.toString() != "Invalid Date") {
        const dataImmatricolazione = parse(this.Quotation.data_immatricolazione, "dd/MM/yyyy", new Date())
        const dataFineCopertura = parse(this.Quotation.fine_copertura, "dd/MM/yyyy", new Date())


        const testDate = new Date(dataFineCopertura.setFullYear(dataFineCopertura.getFullYear() - 10))
        const isETAMore10 = testDate > dataImmatricolazione
        if (isETAMore10) {
          this.$swal({
            icon: "warning",
            title: "Attenzione!",
            text: "L'anzianità del veicolo supera i 10 anni",
            customClass: {
              confirmButton: "btn btn-secondary",
            },
          })
          return false
        }
      }
      //
      // constrollo valorea uto supera> 190K
      //
      if (isOverMoneyLimit) {
        this.$swal({
          icon: "warning",
          title: "Attenzione!",
          text: "Il valore del veicolo supera la soglia assicurabile",
          customClass: {
            confirmButton: "btn btn-secondary",
          },
        })
        return false
      }
      //
      // constrollo quintali
      //
      if (isOverWeightLimit && isAutocarro) {
        this.$swal({
          icon: "warning",
          title: "Attenzione!",
          text: "Il peso dell'autocarro supera i 35 quintali",
          customClass: {
            confirmButton: "btn btn-secondary",
          },
        })
        return false
      }
      return true
    },
    setDatiModelloAuto(x) {
      this.resetAuto()
      if (x) {
        // console.log(x)
        let elem = this.modelsWhitDati.find(f => f.Id == x)
        if (elem[0]) { elem = elem[0] }
        // console.log(elem)
        this.Quotation.cavalli = elem.obj.hp
        this.Quotation.cilindrata = elem.obj.cc
        this.Quotation.quintali = elem.obj.weight
        this.Quotation.kw = elem.obj.kw
      }
    },
    loadComuniByProvincia() {
      const { provincia } = this.Quotation
      if (provincia) {
        zonaStoreModule.actions
          .getComuniByProvincia(null, provincia.Sigla)
          .then(resp => {
            if (resp.data.Comuni.filter(x => x.Comune == this.Quotation.comune).length == 0) {
              this.Quotation.comune = null
              this.Quotation.cap = null
            }
            this.comuni = resp.data.Comuni
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Impossiblie caricare la lista dei comuni",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            })
          })
      }
    },
    loadComuniByProvinciaLeasing() {
      const { leasing_provincia } = this.Quotation
      if (leasing_provincia) {
        zonaStoreModule.actions
          .getComuniByProvincia(null, leasing_provincia.Sigla)

          .then(resp => {
            // this.Quotation.comune_nascita = null
            this.comuni_leasing = resp.data.Comuni
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Impossiblie caricare la lista dei comuni",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            })
          })
      }
    },
    loadComuniByProvinciaNascita() {
      const { provincia_nascita } = this.Quotation
      if (provincia_nascita) {
        zonaStoreModule.actions
          .getComuniByProvincia(null, provincia_nascita.Sigla)
          .then(resp => {
            // this.Quotation.comune_nascita = null
            this.comuni_nascita = resp.data.Comuni
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Impossiblie caricare la lista dei comuni",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            })
          })
      }
    },
    fetchSerie() {
      // const filter = { select: "serie", where: { manufacturer: this.Quotation.marca } }
      if (!this.Quotation.marca) return
      programStoreModule.actions
        .fetchSeries(null, this.Quotation.marca).then(resp => {
          this.series = resp.data.series.map(x => x.serie)
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Impossiblie caricare sere auto",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          })
        })
    },
    fetchMarca() {
      programStoreModule.actions
        .fetchMarca(null)
        .then(resp => {
          this.marche = resp.data.marche.map(x => x.manufacturer)
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Impossiblie caricare marche auto",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          })
        })
    },
    fetchModel() {
      if (!this.Quotation.serie) { return }

      programStoreModule.actions

        .fetchVeicoliModel(null,
          { marca: this.Quotation.marca, serie: this.Quotation.serie })
        .then(resp => {
          this.modelsWhitDati = resp.data.veicoli.map(x => ({ Id: x.model.concat(' ', x.version), obj: x }))
          this.models = resp.data.veicoli.map(x => x.model.concat(' ', x.version))
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Impossiblie caricare la lista modelli auto",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          })
        })
    },
    initComponent() {
      this.routerParam = this.$router
      this.userData = getUserData()
      store.dispatch(`${this.AGENCY_MODULE_NAME}/fetchAgency`, { id: this.userData.DealerID })
        .then(response => {
          this.agency = response.data.agency
        })
      this.fetchProgramsByDealer()
      this.fetchMarca()
      try {
        this.loadComuniByProvincia()
        this.loadComuniByProvincia()
      } catch (err) {}
      zonaStoreModule.actions
        .getNazioni()
        .then(resp => {
          this.countries = resp.data.Nazioni
        })
        .catch(err => {
          console.log("err nazioni", err)
        })
      zonaStoreModule.actions
        .getPovince()
        .then(resp => {
          this.provinceList = resp.data.Province
        })
        .catch(err => {
          console.log("err province", err)
        })
      this.calcoloCoperturaDate()
      this.changeBasePackage()
      if (this.$route.params.id && this.$route.params.id > 0) {
        store
          .dispatch("app-wizard/loadPreventivo", this.$route.params.id)
          .then(resp => {
            if (resp.data.Quotation) {
              this.Quotation = resp.data.Quotation
              if (!this.Quotation.Id) {
                this.$swal({
                  icon: "error",
                  title: "Attenzione!",
                  text: "Impossibile trovare il preventivo",
                  customClass: {
                    confirmButton: "btn btn-secondary",
                  },
                }).then(() => {
                  this.$router.go({ path: "/dealer/quotes" })
                  // throw new Error('Impossibile trovare il preventivo')
                })
              } else {
                if (this.Quotation.isConfirmed != 'Y') {
                  this.Quotation.inizio_copertura = null
                  this.Quotation.fine_copertura = null
                }
                //* Dopo aver letto il programma */
                store.dispatch('app-wizard/fetchProductListByQuotesId', this.Quotation.Id)
                  .then(response => {
                    console.log('resp.data.products', response.data)
                    this.attachments_list = response.data
                      .map(x => ({ linkedObject: 'Product', linkedId: x.productId }))
                  })
                  .catch(err => {})


                this.changeProgram()
                this.changeBasePackage()
                // this.changeBasePackage()
                this.showOverlay = false
              }
            } else {
              throw new Error("Quotation non valida")
            }
          })
          .catch(err => {
            console.log(err)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Impossiblie caricare il Preventivo",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            })
          })
      } else {
        this.showOverlay = false
      }
    },
    returnToBozza() {
      console.log("returnToBozza")
      this.controllo_conferma_documento = false
    },
    prontoPerAttivazione() {
      this.$swal({
        icon: "warning",
        title: "Attenzione!",
        text: "Stai per rendere disponibile il preventivo per l'attivazione. Sicuro di voler continuare?",
        showCancelButton: true,
        confirmButtonText: 'Attiva',
        cancelButtonText: 'Indietro',
        customClass: {
          confirmButton: "btn btn-secondary btn-space",
          cancelButton: "btn btn-primary btn-space",
        },
      }).then(resp => {
        if (resp.isConfirmed) {
          // this.controllo_conferma_documento = true
          this.$forceUpdate()
          const p = new Promise((resolve, reject) => {
            this.validationFormCliente().then(risp => {
              console.log('risp', risp)
              this.validationFormVeicolo().then(ri => {
                console.log('ri', ri)
                this.validationFormPolizza().then(r => {
                  console.log('rrrrr', r)
                  if (!(risp && ri && r)) {
                    this.$swal({
                      icon: "warning",
                      title: "Attenzione!",
                      text: "Compila tutti i campi obbligatori prima di continuare",
                      confirmButtonText: 'Ok',
                      customClass: {
                        confirmButton: "btn btn-primary btn-space",
                      },
                    }).then(() => {
                      this.validationFormCliente()
                      this.validationFormVeicolo()
                      this.validationFormPolizza()
                    })
                  } else {
                    this.settaProntoPerAttivazione()
                  }
                })
              })
            })
          })
        }
      })
    },
    confermaAttivazionePreventivo() {
      this.$swal({
        icon: "warning",
        title: "Attenzione!",
        text: "Stai per attivare una nuova polizza. Sicuro di voler continuare?",
        showCancelButton: true,
        confirmButtonText: 'Attiva',
        cancelButtonText: 'Indietro',
        customClass: {
          confirmButton: "btn btn-secondary btn-space",
          cancelButton: "btn btn-primary btn-space",
        },

      }).then(resp => {
        if (resp.isConfirmed) {
          this.controllo_conferma_documento = true
          this.$forceUpdate()
          const p = new Promise((resolve, reject) => {
            this.validationFormCliente().then(risp => {
              console.log('risp', risp)
              this.validationFormVeicolo().then(ri => {
                console.log('ri', ri)
                this.validationFormPolizza().then(r => {
                  console.log('rrrrr', r)
                  if (!(risp && ri && r)) {
                    this.$swal({
                      icon: "warning",
                      title: "Attenzione!",
                      text: "Compila tutti i campi obbligatori prima di continuare",
                      confirmButtonText: 'Ok',
                      customClass: {
                        confirmButton: "btn btn-primary btn-space",
                      },
                    }).then(() => {
                      this.validationFormCliente()
                      this.validationFormVeicolo()
                      this.validationFormPolizza()
                    })
                  } else {
                    this.confermaPreventivo()
                  }
                })
              })
            })
          })/*
          p.then(res => console.log(res))
            .catch(err => console.log(err))
            */
        } else { this.controllo_conferma_documento = false }
      })
    },
    async settaProntoPerAttivazione() {
      this.showOverlay = true
      try {
        await store
          .dispatch("app-wizard/settaPreventivoProntoPerAttivazione", {
            userData: this.userData,
            Id: this.Quotation.Id,
          })

        this.$swal({
          icon: "success",
          title: "Successo!",
          text: "Il preventivo è confermato per l'attivazione!",
          customClass: {
            confirmButton: "btn btn-success",
          },
        }).then(resp => {
          console.log("resp", resp)
          this.$router.go({
            name: "edit-quotation",
            params: { id: this.Quotation.Id },
          })
        })
      } catch (error) {
        console.log(error)
        this.$swal({
          icon: "error",
          title: "Attenzione!",
          text: 'Verifica la correttezza dei dati inseriti e riprova!',
          customClass: {
            confirmButton: "btn btn-secondary",
          },
        })
      }
    },
    async confermaPreventivo() {
      this.showOverlay = true
      try {
        const response = await store.dispatch("app-wizard/calcolaPreventivo", {
          quotation: this.Quotation,
          userData: this.userData,
        })

        await store
          .dispatch("app-wizard/confermaPreventivo", {
            userData: this.userData,
            Id: this.Quotation.Id,
          })

        this.$swal({
          icon: "success",
          title: "Successo!",
          text: "Il preventivo è stato confermato!",
          customClass: {
            confirmButton: "btn btn-success",
          },
        }).then(resp => {
          console.log("resp", resp)
          this.$router.go({
            name: "edit-quotation",
            params: { id: response.data.Id },
          })
        })
      } catch (error) {
        console.log(error)
        this.$swal({
          icon: "error",
          title: "Attenzione!",
          text: 'Verifica la correttezza dei dati inseriti e riprova!',
          customClass: {
            confirmButton: "btn btn-secondary",
          },
        })
      }
    },

    changeProgram(old = null, pack = null) {
      if (old != pack || !this.Quotation.program) {
        this.Quotation.secondaryProducts = []
        this.Quotation.package = null
        this.Quotation.gap_durata_copertura = null
        this.Quotation.secondaryPackages = []
        this.secondaryProducts = false
      }
      this.checkProduct()
      // this.checkPacchetto()
    },
    resetAuto() {
      this.Quotation.quintali = null
      this.Quotation.cilindrata = null
      this.Quotation.kw = null
      this.Quotation.cavalli = null
    },

    changeMarca(old = null, pk = null) {
      if (old != pk || !this.Quotation.marca) {
        this.Quotation.modello = ''
        this.Quotation.serie = null
        this.resetAuto()
      }
    },
    changeSerie(old = null, pk = null) {
      if (old != pk || !this.Quotation.marca) {
        this.Quotation.modello = null
        this.resetAuto()
      }
    },
    changeBasePackage() {
      try {
        if (this.Quotation.package) {
          const sProduct = this.Quotation.program.products.filter(
            x => x.baseProduct != "Y",
          )
          this.secondaryProducts = sProduct.map(x => ({
            product: x.product.Name,
            packages: this.packagesValidator(x.packages),
          }))
        } else {
          this.secondaryProducts = []
          this.Quotation.secondaryPackages = []
        }
      } catch (ee) {
        console.log(ee)
      }
    },
    checkProduct() {
      if (this.Quotation.program) {
        const product = this.Quotation.program.products.filter(
          x => x.baseProduct == "Y",
        )
        if (product && product.length > 0) {
          this.baseProduct = product[0]
          this.basePackageList = product[0].packages.map(x => x.package)

          this.product = product[0].product.Name
        } else {
          this.Quotation.package = ""
          this.basePackageList = []
          this.secondaryProducts = []
          this.product = ""
        }
      } else {
        this.product = ""
        this.basePackageList = []
        this.secondaryProducts = []
      }
    },

    fetchProgramsByDealer() {
      programStoreModule.actions
        .fetchProgramsByDealer(null, this.userData.id)
        .then(resp => {
          this.programList = resp.data.programs
        })
        .catch(error => {
          console.log(error)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Impossiblie caricare i programmi",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          })
        })
    },
    async validationFormPolizza() {
      const valid = await this.$refs.polizzaRules.validate()
      if (!valid) {
        return false
      }
      return true
    },
    async validationFormCliente() {
      const valid = await this.$refs.clienteRules.validate()
      if (!valid) {
        return false
      }
      return true
    },
    async validationFormVeicolo() {
      const valid = await this.$refs.veicoloRules.validate()
      if (!valid) {
        return false
      }
      return true
    },
    salvaDocumento() {
      this.changeBasePackage()
      //
      //  controllo regole di business
      //
      if (!this.controlloRegoleBusiness()) return

      if (!this.controllo_conferma_documento) {
        this.calcolaPreventivo()
      } else {
        this.confermaAttivazionePreventivo()
      }
    },
    validaPackage() {
      console.log("packagesValidator")
    },
    packagesValidator(list = []) {
      const items = []
      for (const elem of list) {
        try {
          const i = this.validaElemento(elem.package, elem.rules)
          items.push(i)
          if (i.mandatory || i.selected) {
            if (!this.Quotation.secondaryPackages.some(x => x.Id == i.item.Id)) { this.Quotation.secondaryPackages.push(i.item) }
          }
        } catch (err) {
          console.log("error packageValidator", err)
        }
      }

      return items
    },
    showProvvigione(event) {
      this.provvigione = this.provvigione == this.Quotation.provvigione
        ? "******"
        : this.Quotation.provvigione
    },
    validaElemento(elemento, regole) {
      const elem = {
        item: elemento,
        selected: false,
        disabled: false,
        mandatory: false,
        message: [],
      }

      const isIMA = elemento.Name.toLowerCase().includes('ima')
      const isGAP = elemento.Name.toLowerCase().includes('gap') || elemento.Name.toLowerCase().includes('protezione')
      const isVIASAT = elemento.Name.toLowerCase().includes('viasat')
      const isPremium = elemento.Name.toLowerCase().includes('premium')
      const isFranchigia = elemento.Name.toLowerCase().includes('franchigia')

      if (isIMA) {
        if (isPremium) { return this.controlloIMAPremium(elem) }
        return this.controlloIMAMM(elem)
      }

      if (isGAP) {
        if (isPremium) { return this.controlloGAPPremium(elem) }
        return this.controlloGAPMM(elem)
      }

      if (isVIASAT) {
        // const isPremium = elemento.Name.toLowerCase().includes('premium')
        const viasatP = this.Quotation.program.Name.toLowerCase().includes('premium') || isPremium
        if (viasatP) return this.controlloViasatPremium(elem)
        return this.controlloViasatMM(elem)
      }

      if (isFranchigia) {
        return this.controlloFranchigia(elem)
      }

      try {
        // await regole.forEach(itm => {
        for (const itm of regole) {
          let field = null
          switch (itm.field) {
          case "marca":
            field = parseInt(this.Quotation.valore)
            break
          case "valore":
            field = parseInt(this.Quotation.valore)
            break
          case "tipo-auto":
            field = parseInt(this.Quotation.valore)
            break
          default:
            field = parseInt(this.Quotation.valore)
          }

          if (this.testRule(field, itm.action, itm.value)) {
            if (itm.enabled == "N") {
              elem.disabled = true
            }
            if (itm.mandatory == "Y") {
              elem.mandatory = true
            }
          }
        }
      } catch (err) {
        console.log("validaElemento", err)
      }
      return elem
    },
    testRule(field, operator, value) {
      switch (operator) {
      case "=":
        return field == value
      case ">":
        return field > value
      case ">=":
        return field >= value
      case "<":
        return field < value
      case "<=":
        return field <= value
      case "in":
        return value.pets.includes(field)
      case "contains":
        return value.pets.includes(field)
      default:
        return false
      }
    },
    async calcolaPreventivo() {
      this.controllo_conferma_documento = false
      try {
        this.showOverlay = true
        const response = await store.dispatch("app-wizard/calcolaPreventivo", {
          quotation: this.Quotation,
          userData: this.userData,
        })
        if (response.data.ResultCode == 0) {
          await this.$swal({
            icon: "success",
            title: "Successo!",
            text: "Il preventivo è stato calcolato con successo!",
            customClass: {
              confirmButton: "btn btn-success",
            },
          })
          try {
          // this.$forceUpdate()
          // this.$router.navigate()
            await this.$router.push(`/dealer/quotes/${response.data.Data.Id}`)
          } catch (err) {
            this.$router.go(`/dealer/quotes/${response.data.Data.Id}`)
          }
        } else {
          this.$swal({
            icon: "error",
            title: "Attenzione!",
            text: response.data.ResultMessage,
            customClass: {
              confirmButton: "btn btn-secondary",
            },
          })
        }

        this.showOverlay = false
        return
      } catch (error) {
        console.log(error)
        this.showOverlay = false
        this.$swal({
          icon: "error",
          title: "Attenzione!",
          text: 'Verifica la correttezza dei dati inseriti e riprova!',
          customClass: {
            confirmButton: "btn btn-secondary",
          },
        })
      }
    },
    calcoloCoperturaDateCF(newValue, oldValue) {
      if (newValue != oldValue) {
        this.Quotation.gap_durata_copertura = null
        this.calcoloCoperturaDate()
      }
    },
    calcoloCoperturaDate(newValue, oldValue) {
      this.changeBasePackage()
      if (this.Quotation.durata_copertura && this.Quotation.inizio_copertura) {
        const dataInizio = parse(
          this.Quotation.inizio_copertura,
          "dd/MM/yyyy",
          new Date(),
        )
        let mesi = dataInizio.getMonth()
        mesi += parseInt(this.Quotation.durata_copertura, 10)
        const dataFine = format(dataInizio.setMonth(mesi), "dd/MM/yyyy")
        this.Quotation.fine_copertura = dataFine

        // this.Quotation.gap_durata_copertura = null test TODO

        this.calcoloCoperturaDateGAP()
      } else {
        this.Quotation.fine_copertura = null
        this.Quotation.gap_durata_copertura = null
      }
    },
    async calcoloCoperturaDateGAP() {
      try {
        if (this.Quotation.inizio_copertura && this.Quotation.gap_durata_copertura) {
          const dataInizio = parse(
            this.Quotation.inizio_copertura,
            "dd/MM/yyyy",
            new Date(),
          )

          let offsetMesi = 0
          const gapP = await this.Quotation.secondaryPackages.filter(x => x.Name.toLowerCase().includes('protezione') && x.Name.toLowerCase().includes('premium'))
          const gap = await this.Quotation.secondaryPackages.filter(x => x.Name.toLowerCase().includes('protezione'))
          const protezione = await this.Quotation.secondaryPackages.filter(x => x.Name.toLowerCase().includes('protezione'))
          if ((!gapP && !gap && !protezione) || (gapP.length == 0 && gap.length == 0 && protezione.length == 0)) {
            this.Quotation.gap_inizio_copertura = null
            this.Quotation.gap_fine_copertura = null
            return
          }
          if (gapP && gapP.length > 0) {
            offsetMesi = 24
          } else if (gap && gap.length > 0) {
            offsetMesi = 12
          }


          let mesi_inizio = dataInizio.getMonth()
          mesi_inizio += offsetMesi


          const inizio = format(dataInizio.setMonth(mesi_inizio), "dd/MM/yyyy")
          this.Quotation.gap_inizio_copertura = inizio


          const mesi = dataInizio.getMonth() + parseInt(this.Quotation.gap_durata_copertura, 10)

          const dataFine = format(dataInizio.setMonth(mesi), "dd/MM/yyyy")
          this.Quotation.gap_fine_copertura = dataFine
        } else {
          this.Quotation.gap_inizio_copertura = null
          this.Quotation.gap_fine_copertura = null
        }
      } catch (eerr) {}
    },
  },
  setup() {
    const STORE_MODULE_NAME = "app-wizard"
    const AGENCY_MODULE_NAME = "app-agency"
    const APP_STORE_MODULE_NAME = 'app-dynamic'
    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) {
      store.registerModule(STORE_MODULE_NAME, quotationStoreModule)
    }
    if (!store.hasModule(AGENCY_MODULE_NAME)) {
      store.registerModule(AGENCY_MODULE_NAME, agencyStoreModule)
    }
    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) {
      store.registerModule(APP_STORE_MODULE_NAME, quotationStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) {
        store.unregisterModule(STORE_MODULE_NAME)
      }
      if (store.hasModule(AGENCY_MODULE_NAME)) {
        store.unregisterModule(AGENCY_MODULE_NAME)
      }
      if (store.hasModule(APP_STORE_MODULE_NAME)) {
        store.unregisterModule(APP_STORE_MODULE_NAME)
      }
    })
    const { route, router } = useRouter()
    const routeParams = computed(() => route.value.params)

    const DownloadRenderFile = (quotationId, reportCode) => {
      store.dispatch(`${APP_STORE_MODULE_NAME}/downloadRenderFile`, { query: quotationId, reportCode: 'BPREV' })
        .then(res => {
          const binaryString = window.atob(res.data.file) // window.atob(res.data)// Buffer.from(res.data, 'base64').toString()//
          const bytes = new Uint8Array(binaryString.length)
          const mappedData = bytes.map((byte, i) => binaryString.charCodeAt(i))
          const blob = new Blob([mappedData], { type: 'pdf' })
          const fileURL = window.URL.createObjectURL(blob)
          const fileLink = document.createElement('a')
          fileLink.href = fileURL
          fileLink.setAttribute('download', res.data.filename)// 'trst.pdf')// res.data.Data.file.name)
          document.body.appendChild(fileLink)
          fileLink.click()
          URL.revokeObjectURL(fileURL)
        })
        .catch(error => {
          console.log(error)
        })
    }

    return {
      routeParams,
      AGENCY_MODULE_NAME,
      DownloadRenderFile,
    }
  },
}
</script>

  <style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.agency-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

  <style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.checkout-options {
  .coupons {
    &:focus-within {
      box-shadow: none;
    }
    input {
      border: none;
      padding-left: 0;
      color: $body-color;
      font-weight: $font-weight-bolder;
      &::placeholder {
        color: $body-color;
        font-weight: $font-weight-bolder;
      }
    }
    .input-group-append {
      margin: 0;
    }
    .input-group-text {
      height: auto;
      font-weight: $font-weight-bolder;
      padding: inherit;
    }
  }
}
.btn-space {
  margin: 5px;
}
</style>

