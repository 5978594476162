<template>
  <section id="file-dinamici">
    {{ files.templates }}
    <b-list-group>

      <b-list-group-item
        v-for="(file, idx) in files"
        :key="idx"
        class="d-flex"
        @click="DownloadRenderFile(quotationId, file.DocCode)"
      >
        <span class="mr-1">
          <feather-icon
            icon="ImageIcon"
            size="16"
          />
        </span>

        <span>{{ file.templates[0].Description }}</span>
      </b-list-group-item>
    </b-list-group>
    </b-card-body>

  </section>
</template>

<script>
/* eslint-disable vue/no-unused-components */
/* eslint no-unused-expressions: ["error", { "allowShortCircuit": true }] */
import Vue from 'vue'
import { VueAutosuggest } from 'vue-autosuggest'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import {
  BAvatar, BModal, BButton, VBModal, BTable, BCardBody, BCardFooter, BOverlay, BFormFile, BCard, BListGroup,
  BListGroupItem, BCol, BRow,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import { useRouter } from '@core/utils/utils'
import { getUserData } from '@/auth/utils'
import {
  ref, onUnmounted, computed, watch,
} from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import quotationStoreModule from './quotesStoreModule'

export default {
  components: {
    BCardActions,
    BFormFile,
    BButton,
    BListGroup,
    BListGroupItem,
    BModal,
    BCard,
    BCardBody,
    BTable,
    VueAutosuggest,
    BCol,
    BRow,
    BAvatar,
    BCardFooter,
    BOverlay,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: { quotationId: { type: String } },

  setup(props, { emit }) {
    const files = ref([])
    const show = ref(false)
    const APP_STORE_MODULE_NAME = 'app-dynamic'

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, quotationStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })
    const userData = getUserData()
    const fetchFiles = () => {
      console.log("Festch DYNAMIC file", props.quotationId)
      show.value = true
      store.dispatch(`${APP_STORE_MODULE_NAME}/fetchDynamicFileListbyQuotation`, { Id: props.quotationId, userData })
        .then(res => {
          console.log("fetchDynamicFileListbyQuotation", res.data.RDOC)
          files.value.push(...res.data.RDOC)
          /* for (const f of res.data.files) {
               this.files.value.push(...res.data.RDOC)
            }
            */
        })
        .catch(error => {
          console.log('erro files', error)
        }).finally(show.value = false)
    }

    const DownloadRenderFile = (quotationId, reportCode) => {
      store.dispatch(`${APP_STORE_MODULE_NAME}/downloadRenderFile`, { query: quotationId, reportCode })
        .then(res => {
          const binaryString = window.atob(res.data.file) // window.atob(res.data)// Buffer.from(res.data, 'base64').toString()//
          const bytes = new Uint8Array(binaryString.length)
          const mappedData = bytes.map((byte, i) => binaryString.charCodeAt(i))
          const blob = new Blob([mappedData], { type: 'pdf' })
          const fileURL = window.URL.createObjectURL(blob)
          const fileLink = document.createElement('a')
          fileLink.href = fileURL
          fileLink.setAttribute('download', res.data.filename)// 'trst.pdf')// res.data.Data.file.name)
          document.body.appendChild(fileLink)
          fileLink.click()
          URL.revokeObjectURL(fileURL)
        })
        .catch(error => {
          console.log(error)
        })
    }
    const DownloadFile = fullpath => {
      store.dispatch(`${APP_STORE_MODULE_NAME}/fetchFile`, { fullpath })
        .then(res => {
          if (res.data.Data) {
            const binaryString = window.atob(res.data.Data.base64String)
            const bytes = new Uint8Array(binaryString.length)
            const mappedData = bytes.map((byte, i) => binaryString.charCodeAt(i))
            const blob = new Blob([mappedData], { type: res.data.Data.file.mimetype })
            const fileURL = window.URL.createObjectURL(blob)
            const fileLink = document.createElement('a')
            fileLink.href = fileURL
            fileLink.setAttribute('download', res.data.Data.file.name)
            document.body.appendChild(fileLink)
            fileLink.click()
            URL.revokeObjectURL(fileURL)
          } else {
            Vue.prototype.$toast({
              component: ToastificationContent,
              props: {
                title: 'File danneggiato. Ripristinare il file nel pannello di configurazione',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }
        })
        .catch(error => {
          console.log(error)
        })
    }
    watch(props, () => {
      fetchFiles()
    })
    fetchFiles()
    return {
      userData,
      files,
      fetchFiles,
      show,
      DownloadFile,
      DownloadRenderFile,
    }
  },
  methods: {
    formatDateTime(formatedDate) {
      if (formatedDate) {
        const date = new Date(formatedDate) // formated_Date - SDK returned date

        return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`
      } return ""
    },
  },
}


</script>
