import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchFileList(ctx, { linkedObject, linkedId }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/base/attachments', { linkedObject, linkedId })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchFile(ctx, { fullpath }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/base/attachment', { fullpath })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteFile(ctx, { fullpath }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/base/attachment/delete', { fullpath })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
